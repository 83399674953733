<template>
  <el-select
    v-model="taxGroupsModel"
    @change="taxGroupsChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.TAX_GROUPS')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getTaxGroups"
    :loading="loading"
  >
    <el-option
      v-for="taxGroup in taxGroupsList"
      :key="taxGroup.id"
      :value="taxGroup.id"
      :label="`${taxGroup.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "tax-groups-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    taxGroups: {
      type: Array,
      default: null,
      description: "TaxGroups array",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      taxGroupsModel: this.taxGroups
        ? this.taxGroups.map((item) => item.id)
        : [],
      taxGroupsList: {},
    };
  },

  setup() {},

  created() {
    this.getTaxGroups(null, this.taxGroups);
  },

  methods: {
    async getTaxGroups(query = null, taxGroups = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(taxGroups ? { ids: taxGroups.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("taxGroups/list", params);
        const taxGroupsArr = await this.$store.getters["taxGroups/list"];
        this.taxGroupsList = {};
        taxGroupsArr.forEach((taxGroup) => {
          this.taxGroupsList[taxGroup.id] = taxGroup;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    taxGroupsChanged(taxGroups) {
      this.$emit(
        "taxGroupsChanged",
        taxGroups.map((item) => {
          return { id: item, type: "tax-groups" };
        })
      );
    },
  },

  watch: {
    taxGroups(taxGroups) {
      this.taxGroupsModel = taxGroups ? taxGroups.map((item) => item.id) : [];
      this.getTaxGroups(null, taxGroups);
    },
    filterOrganization(filterOrganization) {
      this.getTaxGroups();
    },
  },
};
</script>
