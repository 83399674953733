<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div>
      <dropzone-file-upload
        :key="renderKey"
        v-model="filesModel"
        :url="uploadUrl"
        multiple
        @error="onUploadError"
      >
      </dropzone-file-upload>

      <div v-if="filesModel.length > 0">
        <base-input
          :label="`${$t('COMMON.TAGS')}`"
          :placeholder="`${$t('COMMON.TAGS')}`"
          v-if="filesModel.length"
        >
          <tags-selector
            :tags="tags"
            @tagsChanged="
              (vtags) => {
                tags = vtags;
              }
            "
            :organization="getObjectOrganization()"
          />
        </base-input>

        <!-- Excerpt -->
        <base-input
          :label="$t('COMMON.EXCERPT')"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="excerpt"> </html-editor>
        </base-input>
      </div>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="addFiles"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.ADD_FILES") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import TagsSelector from "@/components/TagsSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    HtmlEditor,
    TagsSelector,
    DropzoneFileUpload,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
      description: "Files linked object",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/${this.object.type}/${this.object.id}/files`;
    },
  },

  watch: {},

  data() {
    return {
      loading: false,
      filesModel: [],
      tags: [],
      excerpt: "",
      renderKey: 0,
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.filesModel = [];
      this.$emit("onCloseModal", refresh);
    },

    getObjectOrganization() {
      return this.object.organization?.id;
    },

    async addFiles() {
      this.loading = true;
      const me = this.$store.getters["profile/me"];

      let organization = null;
      let allowedLocations = [];
      if (this.object.organization) {
        organization = {
          id: this.object.organization.id,
          type: "organizations",
        };
      }
      if (this.object.allowedLocations) {
        if (this.object.allowedLocations.length === 0) {
          allowedLocations = [
            ...this.object.allowedLocations.map((item) => ({
              type: "locations",
              id: item.id,
            })),
          ];
        }
      }
      if (allowedLocations.length === 0) {
        if (me.restrict_to_locations) {
          allowedLocations = [
            ...me.allowedLocations.map((item) => ({
              type: "locations",
              id: item.id,
            })),
          ];
        }
      }

      const fileDataBase = {
        type: "files",
        excerpt: this.excerpt,
        relationshipNames: [
          "organization",
          "object",
          "tags",
          "allowedLocations",
        ],
        object: {
          type: this.object.type,
          id: this.object.id,
        },
        tags: this.tags,
        ...(organization ? { organization: organization } : {}),
        allowedLocations: allowedLocations,
      };

      for (const file of this.filesModel) {
        const fileData = cloneDeep(fileDataBase);
        fileData.name = file.upload.filename;
        fileData.path = JSON.parse(file.xhr.response).path;

        try {
          await this.$store.dispatch("files/add", fileData);
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
          return;
        }
      }

      this.$notify({
        type: "success",
        message: this.$t("FILES.FILE_ADDED"),
      });
      this.closeModal(true);
      this.loading = false;
    },

    async onUploadError(error) {
      if (error.status === "error") {
        if (error.xhr) {
          let errorMessage = this.$t("ERRORS.FILE_UPLOAD_ERROR");
          if (error.xhr.response) {
            errorMessage = error.xhr.response;
          } else {
            errorMessage = error.xhr.statusText;
          }
          await swal.fire({
            title: this.$t(`ERRORS.FILE_UPLOAD_ERROR`),
            html: errorMessage,
            icon: "error",
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
          this.renderKey = this.renderKey + 1;
        }
      }
      this.loading = false;
    },
  },

  watch: {
    showModal(showModal) {
      this.renderKey = this.renderKey + 1;
    },
  },
};
</script>
