<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-12">
        <base-input
          :value="mail.from"
          :readOnly="true"
          :label="`${$t(`COMMON.MAIL_FROM`)}`"
        />
        <validation-error :errors="apiValidationErrors.from" />
      </div>
      <div class="col-12">
        <base-input
          :label="`${$t(`COMMON.MAIL_TO`)}`"
          :placeholder="$t('COMMON.SELECT_CONTACTS')"
        >
          <el-select
            v-model="mail.to"
            autocomplete="new-password"
            :label="`${$t(`COMMON.MAIL_TO`)}`"
            :placeholder="$t('COMMON.SELECT_CONTACTS')"
            :filterable="true"
            :multiple="true"
            remote
            :remote-method="getEstablishmentContacts"
            :loading="loadingContacts"
            :allow-create="true"
            default-first-option
            @focus="getEstablishmentContacts()"
          >
            <el-option
              v-for="contact in contactsList"
              :key="contact.id"
              :value="contact.email"
              :label="`${contact.firstname} ${contact.lastname} - ${contact.email}`"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.to" />
      </div>

      <div class="col-12">
        <base-checkbox
          @input="onFormChanged"
          v-model="mail.sendMeCopy"
          class=""
        >
          <span class="form-control-label">
            {{ $t("COMMON.MAIL_SEND_ME_COPY") }}
          </span>
        </base-checkbox>
      </div>
      <div class="col-12">
        <base-input
          :label="$t('COMMON.MAIL_CC')"
          :placeholder="$t('COMMON.SELECT_CONTACTS')"
        >
          <el-select
            v-model="mail.cc"
            autocomplete="new-password"
            :label="`${$t(`COMMON.MAIL_CC`)}`"
            :placeholder="$t('COMMON.SELECT_CONTACTS')"
            :filterable="true"
            :multiple="true"
            remote
            :remote-method="getContacts"
            :loading="loadingContacts"
            :allow-create="true"
            default-first-option
          >
            <el-option
              v-for="contact in contactsList"
              :key="contact.id"
              :value="contact.email"
              :label="`${contact.firstname} ${contact.lastname} - ${contact.email}`"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.cc" />
      </div>
      <div class="col-12">
        <base-input
          :label="$t('COMMON.MAIL_BCC')"
          :placeholder="$t('COMMON.SELECT_CONTACTS')"
        >
          <el-select
            v-model="mail.bcc"
            autocomplete="new-password"
            :label="`${$t(`COMMON.MAIL_BCC`)}`"
            :placeholder="$t('COMMON.SELECT_CONTACTS')"
            :filterable="true"
            :multiple="true"
            remote
            :remote-method="getContacts"
            :loading="loadingContacts"
            :allow-create="true"
            default-first-option
          >
            <el-option
              v-for="contact in contactsList"
              :key="contact.id"
              :value="contact.email"
              :label="`${contact.firstname} ${contact.lastname} - ${contact.email}`"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.bcc" />
      </div>
      <div class="col-12">
        <base-input
          v-model="mail.subject"
          :label="`${$t(`COMMON.MAIL_SUBJECT`)} (*)`"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.subject" />
      </div>
      <div class="col-12">
        <h2 class="">{{ $t("COMMON.MAIL_BODY") }}</h2>
        <html-editor v-model="mail.body" @change="onFormChanged"></html-editor>
        <validation-error :errors="apiValidationErrors.body" />
      </div>
    </div>
    <div class="d-flex justify-content-start">
      <el-button :disabled="submitting" @click="handleSubmit" type="success">
        {{ $t("COMMON.SEND") }}
      </el-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultSalesInvoiceMailData from "@/constants/defaultSalesInvoiceMailData";
import { formatNumber, parseNumber } from "libphonenumber-js";

export default {
  layout: "DashboardLayout",
  name: "documents-send-mail-form",

  components: {
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    documentData: {
      type: Object,
      require: true,
    },
    toggleMailModal: {
      type: Function,
    },
    mailInformation: {
      type: Object,
    },
    loading: {
      default: () => true,
    },
    dispatchUrl: {
      type: String,
      require: true,
    },
    link: {
      type: [String, null],
    },
  },

  data() {
    return {
      mail: defaultSalesInvoiceMailData,
      user: null,
      submitting: false,
      formErrors: {},
      contactsList: {},
      contactsModel: [],
      loadingContacts: false,
    };
  },

  created() {},

  mounted() {
    this.getUserInfo();
    this.getEstablishmentContacts();
  },

  methods: {
    async handleSubmit() {
      this.submitting = true;
      let mailData = cloneDeep(this.mail);
      mailData.to = mailData.to.join(",");
      mailData.cc = mailData.cc.join(",");
      mailData.bcc = mailData.bcc.join(",");

      if (!mailData?.cc) {
        delete mailData.cc;
      }
      if (!mailData?.bcc?.length) {
        delete mailData.bcc;
      }
      if (!mailData?.body) {
        delete mailData.body;
      }

      try {
        await this.$store.dispatch(this.dispatchUrl, {
          ...mailData,
          id: this.documentData?.id,
        });
        this.$notify({
          type: "success",
          message: this.$t("COMMON.EMAIL_SENT_SUCCESSFULLY"),
        });
        this.submitting = false;
        if (this.toggleMailModal) {
          this.toggleMailModal();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.submitting = false;
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    formatPhoneNumber(phoneNumber) {
      try {
        return formatNumber(parseNumber(phoneNumber), "NATIONAL");
      } catch (e) {
        return phoneNumber;
      }
    },

    async getUserInfo() {
      await this.$store.dispatch("profile/me");
      this.user = this.$store.getters["profile/me"];
      this.mail = {
        ...this.mail,
        from: `${this.user.firstname} ${this.user.lastname} <${this.user.email}>`,
        to: [this.mailInformation?.recipient?.email],
        subject: this.mailInformation?.subject,
        body: this.mailInformation.body
          ? this.mailInformation.body
          : this.$t("COMMON.EMAIL_INFOS_BODY", {
              username: this.mailInformation?.recipient?.username,
              sender_name: `${this.user?.firstname} ${this.user?.lastname}`,
              phone: `${
                this.user.phone_type
                  ? this.$t(`COMMON.PHONE_TYPE_${this.user.phone_type}`) + ":"
                  : ""
              } ${this.formatPhoneNumber(this.user.phone)}${
                this.user.phone_extension
                  ? ` (ext: ${this.user.phone_extension})`
                  : ""
              }`,
              email: this.user?.email,
              organization: this.mailInformation?.organization?.name,
              link: this.link,
            }),
      };
    },

    async getEstablishmentContacts(query = null, contacts = null) {
      this.getContacts(query, contacts, this.documentData.establishment);
    },

    async getContacts(query = null, contacts = null, establishment = null) {
      try {
        this.loadingContacts = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
          include: "contactable",
        };

        if (this.documentData) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              ...(this.documentData.organization &&
              this.documentData.organization.id
                ? { organization: this.documentData.organization.id }
                : {}),
              ...(establishment
                ? {
                    contactableType: "App\\Models\\Establishment",
                    contactableId: establishment.id,
                  }
                : {}),
            },
          };
        }

        await this.$store.dispatch("contacts/list", params);
        const contactsArr = await this.$store.getters["contacts/list"];
        this.contactsList = {};
        contactsArr.forEach((contact) => {
          this.contactsList[contact.id] = contact;
        });
        this.loadingContacts = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loadingContacts = false;
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    mailInformation() {
      this.getUserInfo();
    },
    documentData() {
      this.getEstablishmentContacts();
    },
  },
};
</script>
