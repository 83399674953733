<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ salesOrder.seq_number }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.updated_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("SALES_ORDERS.EXPIRATION_TIME") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.expiration_time) }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="salesOrder.cafeteria" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="salesOrder.establishment" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <service-center :serviceCenter="salesOrder.serviceCenter" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="salesOrder.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="salesOrder.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <sales-order-status-badge
              :salesOrder="salesOrder"
              :advanced="true"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.HAS_TAXES") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!salesOrder.has_no_taxes" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PAYMENT_TERMS") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesOrder.paymentTerm" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link
              v-if="!!salesOrder.sourceWarehouse"
              :object="salesOrder.sourceWarehouse"
            />
            <span v-if="!salesOrder.sourceWarehouse">
              {{ $t("COMMON.NONE") }}
            </span>
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.salesInvoice">
          <dt class="col-sm-4">{{ $t("COMMON.SALES_INVOICE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesOrder.salesInvoice" />
            <sales-invoice-status-badge
              :salesInvoice="salesOrder.salesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesOrder.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="salesOrder.pricing">
            <span> {{ $formatCurrency(salesOrder.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.event_date">
          <dt class="col-sm-4">
            {{ $t("SALES_ORDERS.EVENT_DATE") }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.event_date, "LL") }}
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.event_date">
          <dt class="col-sm-4">
            {{ $t("SALES_ORDERS.EVENT_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.event_date, "HH:mm") }}
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.delivery_location">
          <dt class="col-sm-4">
            {{ $t("SALES_ORDERS.DELIVERY_LOCATION") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="salesOrder.delivery_location"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesOrder.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="salesOrder.excerpt">
            <div v-html="salesOrder.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesOrder.terms_conditions">
            {{ $t("COMMON.TERMS_CONDITIONS") }}
          </dt>
          <dd class="col-sm-8" v-if="salesOrder.terms_conditions">
            <div v-html="salesOrder.terms_conditions"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesOrder.description">
            {{ $t("COMMON.DESCRIPTION") }}
          </dt>
          <dd class="col-sm-8" v-if="salesOrder.description">
            <div v-html="salesOrder.description"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl class="row" v-if="salesOrder.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_lastname }}
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_firstname }}
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import IconCheck from "@/components/IconCheck.vue";
import SalesInvoiceStatusBadge from "../../SalesInvoiceManagement/partials/SalesInvoiceStatusBadge.vue";
import SalesOrderStatusBadge from "./SalesOrderStatusBadge.vue";

export default {
  name: "sales-order-view-global",

  components: {
    SalesOrderStatusBadge,
    SalesInvoiceStatusBadge,
    Cafeteria,
    Establishment,
    ServiceCenter,
    IconCheck,
  },

  props: ["salesOrder"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesOrder(salesOrder) {},
  },
};
</script>
