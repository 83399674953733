<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
      establishment &&
      establishment.id
    "
  >
    <router-link
      :to="{ name: 'View Establishment', params: { id: establishment.id } }"
    >
      {{ `${establishment.company_name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "establishment",

  components: {},

  mixins: [formMixin],

  props: {
    establishment: {
      type: Object,
      default: () => ({}),
      description: "Establishment",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
