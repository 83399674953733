<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ salesOrder.seq_number }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">
            {{
              salesOrder.client_validated_status ==
              CLIENT_VALIDATED_STATUS_VALIDATED
                ? $t("SALES_ORDERS.VALIDATED_AT")
                : $t("SALES_ORDERS.CANCELED_AT")
            }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesOrder.client_validated_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <sales-order-client-validated-status-badge
              :salesOrder="salesOrder"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.client_validated_by_firstname }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.client_validated_by_lastname }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.client_validated_by_email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.POSITION") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.client_validated_by_position }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("SALES_ORDERS.COMMENT") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.client_validated_by_comment }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CLIENT_VALIDATED_STATUS_DRAFT,
  CLIENT_VALIDATED_STATUS_CANCELED,
  CLIENT_VALIDATED_STATUS_VALIDATED,
} from "@/constants/salesOrders";
import SalesOrderClientValidatedStatusBadge from "./SalesOrderClientValidatedStatusBadge.vue";

export default {
  name: "sales-order-view-global",

  components: {
    SalesOrderClientValidatedStatusBadge,
  },

  props: ["salesOrder"],

  data() {
    return {
      CLIENT_VALIDATED_STATUS_DRAFT,
      CLIENT_VALIDATED_STATUS_CANCELED,
      CLIENT_VALIDATED_STATUS_VALIDATED,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesOrder(salesOrder) {},
  },
};
</script>
