import { render, staticRenderFns } from "./SalesOrderViewDeliveryState.vue?vue&type=template&id=37e2d320&scoped=true"
import script from "./SalesOrderViewDeliveryState.vue?vue&type=script&lang=js"
export * from "./SalesOrderViewDeliveryState.vue?vue&type=script&lang=js"
import style0 from "./SalesOrderViewDeliveryState.vue?vue&type=style&index=0&id=37e2d320&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e2d320",
  null
  
)

export default component.exports