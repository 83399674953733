<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="salesOrder"
          :dispatchUrl="'salesOrders/sendMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div class="col-12 col-md-7" style="max-height: 100%">
        <sales-order-view-details
          :showHeader="false"
          :salesOrder="salesOrder"
          @salesOrderItemsUpdated="get"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import {formatNumber, parseNumber} from 'libphonenumber-js';
import { Button } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import salesOrderViewDetails from "./SalesOrderViewDetails.vue";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";

const baseAppUrl = process.env.VUE_APP_BASE_URL;

export default {
  layout: "DashboardLayout",
  name: "sales-order-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    [Button.name]: Button,
    salesOrderViewDetails,
  },

  mixins: [formMixin],
  props: {
    salesOrder: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},

  mounted() {
    this.refreshMailInformations();
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      try {
        return formatNumber(parseNumber(phoneNumber), "NATIONAL");
      } catch (e) {
        return phoneNumber;
      }
    },

    refreshMailInformations() {
      this.user = this.$store.getters["profile/me"];

      const route = this.$router.resolve({
        name: "Validate SalesOrder",
        params: { id: this.salesOrder.id, token: this.salesOrder.public_token },
      });

      this.mailInformation = {
        organization: this.salesOrder.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_ORDER", {
          organization: this.salesOrder.organization?.name,
          seq_number: this.salesOrder.seq_number,
        }),
        recipient: {
          username: this.salesOrder?.recipient?.customer_name,
          email:
            this.salesOrder?.recipient?.billing_email ??
            this.salesOrder?.recipient?.email,
        },
        body: this.$t("COMMON.SALES_ORDER_EMAIL_INFOS_BODY", {
          username: this.salesOrder?.recipient?.customer_name,
          sender_name: `${this.user.firstname} ${this.user.lastname}`,
          phone: `${
            this.user.phone_type
              ? this.$t(`COMMON.PHONE_TYPE_${this.user.phone_type}`) + ":"
              : ""
          } ${this.formatPhoneNumber(this.user.phone)}${
            this.user.phone_extension
              ? ` (ext: ${this.user.phone_extension})`
              : ""
          }`,
          email: this.user.email,
          organization: this.salesOrder.organization.name,
          link: `
            <p data-class="link">
              <a class="button" 
                href="${baseAppUrl + route.href}" 
                rel="noopener noreferrer" target="_blank"
              >
                ${this.$t("COMMON.VALIDATE_SALES_ORDER")}
              </a>
            </p>
          `,
        }),
      };
    },
  },

  watch: {
    salesOrder(salesOrder) {
      this.refreshMailInformations();
    },
  },
};
</script>
